import React from "react";

function FeatureRequests() {
  return (
    <div style={{height:"100vh", width:"100vw", color:"white", justifyContent:"center"}}>
      <div className="px-2" style={{height:"100vh", width:"100vw", color:"white", justifyContent:"center"}}>
        <div>
        <h4 style={{color:"green"}}>
          Feature Roadmap
        </h4>
        </div>
        <p>
          <strong>Features Added in version 2.45:</strong>
          <ul>
            <li>Google OAuth Login</li>
            <li>720P HLS streaming with 30 seconds chunks</li>
            <li>Streams can be casted from Windows devices to TV Screens using same network</li>
            </ul>
        </p>
        <p>
          <strong>Planned Updates till version 2.9:</strong>
          <ul>
            <li>Adaptive esolution streaming 480p, 720p, 1080p based on network speed</li>
            <li>Captions</li>
            <li>Language Selection</li>
            <li>Chromecasting options</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default FeatureRequests;
