import React , { useState, useEffect }  from 'react'

function Timer({ timersecs }) {
  const [seconds, setSeconds] = useState(timersecs);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(timer); // Clear the interval when seconds reach 0
          return 0;
        }
      });
    }, 1000);

    // Cleanup the timer when the component is unmounted
    return () => clearInterval(timer);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  return <div>OTP Valid for: {seconds} seconds</div>;
}

export default Timer;
  