import React, { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { fetchMongoDbData } from "../utils/fetchMongoDbData";
import { useStateContext } from "../hooks/StateContextProvider";
import LoadingPage from "../Component/LoadingComponent";
import { BsTrash } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { updateMongoDbData } from "../utils/updateMongoDbData";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "../Component/DeleteConfirmationModal";
import "./css/DatabasePage.css";
import EditModal from "../Component/EditModal";
import { Link } from "react-router-dom";

function DatabasePage() {
  const [mongoDB, setMongoDB] = useState([]);
  const { user } = useAuthContext();
  const { isLoading, setIsLoading, searchTerm } = useStateContext();
  const [showModal, setShowModal] = useState({
    delete: false,
    edit: false,
  });
  const [deleteItems, setDeleteItems] = useState({ id: "", path: "null" });
  const [activeItem, setActiveItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

    const fetchData = async () => {
    if (user) {
      setIsLoading(true);
      const mongoDbSeriesData = await fetchMongoDbData(
        "Series",
        "",
        user.accessToken
      );
      const mongoDbMoviesData = await fetchMongoDbData(
        "Movies",
        "",
        user.accessToken
      );
      setMongoDB([...mongoDbSeriesData, ...mongoDbMoviesData]);
      setIsLoading(false);
      toast.success("Success");
    }
  };

  const handleUpdateDatabase = async () => {
    if (user.accessToken) {
      updateMongoDbData(user.accessToken, setIsLoading, fetchData);
      toast.info("Requested Database update");
    } else {
      toast.error("Error! Please login again");
    }
  };

  const openModal = (id, path, item) => {
    setDeleteItems({ id, path });
    setActiveItem(item);
    setShowModal({
      delete: !showModal.delete,
    });
  };

  const closeModal = () => {
    setShowModal({
      delete: !showModal.delete,
    });
  };

  const openEditModal = (item) => {
    setActiveItem(item);
    setShowModal({
      edit: !showModal.edit,
    });
  };

  const closeEditModal = () => {
    setShowModal({
      edit: !showModal.edit,
    });
  };

  // Calculate the items to display based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchTerm
    ? mongoDB.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : mongoDB.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="page">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <button onClick={fetchData}>
              {isLoading ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                "Get Data"
              )}
            </button>
          </div>
          <div className="col">
            <button onClick={() => console.log("Edit Function Pending")}>
              Edit Data
            </button>
          </div>
          <div className="col">
            <button onClick={() => console.log("Update Function Pending")}>
              Update Data
            </button>
          </div>
          <div className="col">
            <Link to="/user/searchTor">
            <button type="button" className="btn btn-primary text-white">
            Search Torrent
            </button>
            </Link>
          </div>
          <div className="col">
            <button onClick={handleUpdateDatabase}>
              {isLoading ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                "Sync Database"
              )}
            </button>
          </div>
        </div>
      </div>
      {mongoDB.length ? (
        <>
          {!isLoading ? (
            <>
              {showModal.delete && (
                <DeleteConfirmationModal
                  show={showModal.delete}
                  onHide={(e) => closeModal()}
                  user= {user}
                  path={deleteItems.path}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  item={activeItem}
                  setDeleteItems={setDeleteItems}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  handleUpdateDatabase={handleUpdateDatabase}
                />
              )}
              {showModal.edit && (
                <EditModal
                  show={showModal.edit}
                  onHide={(e) => closeEditModal()}
                  item={activeItem}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  fetchData={fetchData}
                />
              )}
              <div style={{ height: "75vh" }} className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">Title</th>
                      <th scope="col">Type</th>
                      <th scope="col">Episodes</th>
                      <th scope="col">Path</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.type}</td>
                        <td>{item.path.length}</td>
                        <td>
                          {item.type === "Series" ? (
                            <>
                              <select
                                
                              >
                                {item.path.map((pathItem, pathIndex) => (
                                  <option
                                    key={"path" + pathIndex}
                                    value={pathItem}
                                  >
                                    {pathItem.split("/")[2].split(".")[0]}
                                  </option>
                                ))}
                              </select>
                              <button onClick={(e) => openModal(item._id, e.target.value, item)}>
                                <BsTrash />
                              </button>
                              <button onClick={() => openEditModal(item)}>
                                <CiEdit />
                              </button>
                            </>
                          ) : (
                            <>
                              <div>{item.title}</div>
                              <button
                                onClick={(e) =>
                                  openModal(item._id, item.path[0], item)
                                }
                              >
                                <BsTrash style={{ marginRight: "4px" }} />
                              </button>
                              <button onClick={() => openEditModal(item)}>
                                <CiEdit />
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                {/* Previous Button */}
                <button
                  onClick={() => paginate(currentPage - 1)}
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                {/* Page Buttons */}
                {Array.from(
                  { length: Math.ceil(mongoDB.length / itemsPerPage) },
                  (_, i) => {
                    // Display up to 3 buttons at a time around the current page
                    const page = i + 1;
                    const minPage = Math.max(currentPage - 1, 1);
                    const maxPage = Math.min(
                      currentPage + 1,
                      Math.ceil(mongoDB.length / itemsPerPage)
                    );
                    if (page >= minPage && page <= maxPage) {
                      return (
                        <button
                          key={page}
                          onClick={() => paginate(page)}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          {page}
                        </button>
                      );
                    }
                    return null;
                  }
                )}

                {/* Next Button */}
                <button
                  onClick={() => paginate(currentPage + 1)}
                  className={`page-item ${
                    currentPage === Math.ceil(mongoDB.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    currentPage === Math.ceil(mongoDB.length / itemsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <LoadingPage />
          )}
        </>
      ) : (
        <p className="d-flex justify-content-center text-white">
          Click on Get Data
        </p>
      )}
    </div>
  );
}

export default DatabasePage;
