import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Form, Button, Card } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs'; // Import the delete/trash icon
import { Link } from 'react-router-dom';
import { useAuthContext } from "../hooks/useAuthContext";
import { toast } from "react-toastify";

function TodoPage() {
    const [todos, setTodos] = useState([]);
    const [title, setTitle] = useState('');
    const [language, setLanguage] = useState('');
    const { user } = useAuthContext();

    const fetchAllTodos = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + '/api/request/fetch',
                {
                    headers: {
                        "x-access-token": user.accessToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            setTodos(response.data.todos);
            toast.success(response.data.message);
        } catch (error) {
            toast.error('Error fetching todos: ' + error.response?.data?.message);
        }
    }, [user.accessToken]); // Ensure user.accessToken is a dependency

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (title && language) {
            const data = { title, language };

            try {
                const response = await axios.post(process.env.REACT_APP_BACKEND + '/api/request/add',
                    data,
                    {
                        headers: {
                            "x-access-token": user.accessToken,
                            "Content-Type": "application/json",
                        },
                    }
                );
                setTitle("");
                setLanguage("");
                toast.success(response.data.message);
                fetchAllTodos();
            } catch (error) {
                console.log(error);
                toast.error('Error posting todo: ' + error.response?.data?.message);
            }
        } else {
            toast.info("You need to add both Name of the Show/Movie and Language/Year");
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_BACKEND + '/api/request/delete',
                {
                    headers: {
                        "x-access-token": user.accessToken,
                        "Content-Type": "application/json",
                    },
                    data: { id },
                }
            );

            if (response.data.success) {
                fetchAllTodos();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
        }
    };

    const handleStatus = async (id) => {
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND + '/api/request/update',
                { id },
                {
                    headers: {
                        "x-access-token": user.accessToken,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.success) {
                fetchAllTodos();
            } else {
                toast(response.data.message);
            }
        } catch (error) {
            toast(error.response?.data?.message);
        }
    };

    useEffect(() => {
        fetchAllTodos();
    }, [fetchAllTodos]); // Ensure fetchAllTodos is included as a dependency

    //render Admin Button
    const Admin = () => {
        if(user.roles.includes('ROLE_ADMIN'))
    {
        return<Link to="/user/adminPage">
        <Button
            variant="secondary"
            className="mt-2"
        >
            Admin
        </Button>
        </Link>
    }
    else{
        return null
    }
}

    return (
        <div style={{ marginBottom: "700px" }}>
            <div className="container2">
                <Card className="card" style={{ width: '20rem' }}>
                    <Card.Body>
                        <Card.Title>Submit your Request</Card.Title>
                        <Form>
                            {/* Title */}
                            <Form.Group controlId="formBasicTitle">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter Show/Movie title"
                                />
                            </Form.Group>

                            {/* Language */}
                            <Form.Group controlId="formBasicLanguage">
                                <Form.Label>Language</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="language"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    placeholder="Language"
                                />
                            </Form.Group>

                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                {/* submit button */}
                                <Button
                                    className="mt-2"
                                    variant="success"
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    Submit
                                </Button>

                                {/* Admin button */}
                               
                                <Admin />
                               
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

            {/* List of requested movies */}
            <div className='d-flex justify-content-center mt-1 mb-1'>
                <h1 className='text-white'>Request Status</h1>
            </div>
            <div className='d-flex justify-content-center'>
                <div>
                    {todos && todos.length > 0 ? (
                        <table className="table table-dark table-stripped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Language</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {todos.map((todo, index) => (
                                    <tr key={todo._id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{todo.title}</td>
                                        <td>{todo.language}</td>
                                        <td>
                                            <Button size="sm" className="text-white" variant={todo.complete ? "outline-success" : "outline-secondary"}
                                                onClick={() => handleStatus(todo._id)}
                                            >
                                                {todo.complete ? "Complete" : "Pending"}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                className='ml-2'
                                                onClick={() => handleDelete(todo._id)}
                                            >
                                                <BsTrash style={{ marginRight: '4px' }} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <li className='d-flex justify-content-center text-white'>No new requests are available, submit your request and we will add that show or movie</li>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TodoPage;
