import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./LoadingComponent";

const DelayedNavigation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/user/allmovies");
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
    <Loading/>
)
}

export default DelayedNavigation;
