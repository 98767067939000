// src/utils/fetchData.js
import axios from "axios";

export const fetchMongoDbData = async (key, value, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/api/mongo/fetch`,
      {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          key, // Pass key as a query parameter
          value,
        },
      }
    );
    return response.data.mongoDbData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;  // Rethrow the error to be handled in the component
  }
};
