import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import './Home.css'
import { toast } from 'react-toastify';
import axios from "axios";

export default function AuthComponent() {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        const apiKey = '15d2ea6d0dc1d476efbca3eba2b9bbfb';
        const response = await fetch(
          `https://api.themoviedb.org/3/discover/movie?api_key=${apiKey}&sort_by=popularity.desc&primary_release_year=${currentYear},${lastYear}&page=1&vote_count.gte=100`
        );

      await axios.get(
          `${process.env.REACT_APP_BACKEND}/free`,
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const movieResults = data.results;
        setMovies(movieResults);

      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    };

    fetchMovies();
  }, []); // Run once on component mount


  return (
    <>    
    <div className="home-page">
    <Row  className="register-button text-white"  >
        <Col className="text-center" >
        
        <h1 className='title_text'>Welcome to MS-Media</h1>
        
        <p className='title_text'>Watch the latest movies and TV shows</p>
        
        <Link to="/register"><Button variant="primary">Register Now</Button></Link>
        </Col>
      </Row>
      <div className="background-grid">
        {movies.map((movie, index) => (
          <img
            key={movie.id}
            className={`train-element movie-item-${index}`}
            src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
            alt={movie.title}
            style={{opacity:"60%"}}
          />
        ))}
      </div>
    </div>
    </>
  );
};