import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { renameAWSData } from '../utils/updateMongoDbData';
import { useAuthContext } from '../hooks/useAuthContext';

const EditModal = ({ show, onHide, item, isLoading, setIsLoading, fetchData}) => {
    const {user} = useAuthContext();
    const [newName, setNewName] = useState('');
    const [newSeriesName, setNewSeriesName] = useState('');
    const [category, setCategory] = useState('');
    const [language, setLanguage] = useState('');
    const [newPath, setNewPath] = useState('');



    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newName.trim() === '' || category === '' || language === '') {
            alert('All fields are required');
            return;
        }
        const updatedPath = `${category}/${language}/${newName}`;
        await renameAWSData(user.accessToken, setIsLoading, fetchData, item.path[0], updatedPath, item._id )
        // You can perform other actions here, such as sending the data to a server
        setNewPath(`${category}/${language}/${newName}`);
        setNewName('');
        setCategory('');
        setLanguage('');
        // onHide();
    };

    useEffect(() => {
        console.log(item);
        console.log(newPath);
    }, [newPath, item]);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Update Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formItemName">
                        <Form.Label>Current Item Name: {item.path[0].split("/")[2]}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter new item name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCategory">
                        <Form.Label>Current Category: {item.path[0].split("/")[0]}</Form.Label>
                        <Form.Control
                            as="select"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            <option value="Movies">Movies</option>
                            <option value="Series">Series</option>
                        </Form.Control>
                    </Form.Group>
                    {category ==="Movies" &&
                        <Form.Group controlId="formLanguage">
                        <Form.Label>Current Language: {item.path[0].split("/")[1]}</Form.Label>
                        <Form.Control
                            as="select"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <option value="">Select Language</option>
                            <option value="English">English</option>
                            <option value="Hindi">Hindi</option>
                        </Form.Control>
                    </Form.Group>
                    }
                    {category ==="Series" &&
                        <Form.Group controlId="formItemName">
                        <Form.Label>Current Item Name: {item.path[0].split("/")[2]}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter new Series name"
                            value={newSeriesName}
                            onChange={(e) => setNewSeriesName(e.target.value)} 
                        />
                    </Form.Group>
                    }
                    <Form.Label>Current path: {item.path}</Form.Label>
                    {newPath && <Form.Label>New path: {newPath}</Form.Label>}
                    <Button variant="primary" type="submit">
                        Update Item
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditModal;
