import React, { useEffect, useState } from 'react';
// import { fetchData } from "../utils/fetchData";
// import { showURL } from '../hooks/FetchShowImages';
import LoadingPage from '../Component/LoadingComponent';
import ShowBlocks from '../Component/ShowBlocks';
import { useStateContext } from "../hooks/StateContextProvider";
import { useAuthContext } from "../hooks/useAuthContext";
import {toast} from 'react-toastify';
import { fetchMongoDbData } from '../utils/fetchMongoDbData';


export default function Shows() {
  const [finalData, setFinalData] = useState([]);
  const { searchTerm } = useStateContext();
  const { user } = useAuthContext();


  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try{
        const series = await fetchMongoDbData("Series", "", user.accessToken);
      // const showLinks = await showURL(series);
      setFinalData(series);
    }catch(error){
      toast.error(error.response.data.message);
    }
  }

  fetchDataFromAPI();
}, [user]);

  const getEpisode = (item) => {

    let parts = item.split('/');
    let lastPart = parts[parts.length - 2]; // Access the last element of the array using parts.length - 1
    let episodeInfoWithExtension = lastPart.split(' ')[lastPart.split(' ').length - 1]; // Access the last element after splitting by ' '
    // let episodeInfoWithoutExtension = episodeInfoWithExtension.split('.')[0]; // Remove the file extension
    return episodeInfoWithExtension;
  }


  return (
    <>
      <div className='text-white'style={{ display: "flex", justifyContent: "center" }}>{
        finalData.length ?
          (
            searchTerm 
            ?
            <ShowBlocks finalData={finalData.filter((item) =>
              item.title.toLowerCase().includes(searchTerm.toLowerCase())
            )} getEpisode={getEpisode}  />
            :
            <div>
            <ShowBlocks finalData={finalData} getEpisode={getEpisode}  />
            
            </div>
            ) : (
            <LoadingPage />
          )}

      </div>
    </>
  )


};












