import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Card, Spinner } from 'react-bootstrap';
import '../App.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    setIsLoading(true);
    const formData = {
      username:username,
      email:email,
      password:password
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/auth/signup`,
        formData
      );
      // console.log(response);
      if (response.status === 200) {
        toast.success("Signup successful! Redirecting to login");
        // Redirect to login or another page
        setIsLoading(false);
        setEmail("");
        setUsername("");
        setPassword("");
        setTimeout(() => {
          // setIsLoading(false);
          return navigate("/login");
        }, 3000);
      } else {
        toast.error("Signup failed.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="container">
        <Card className="card" style={{ width: '20rem' }}>
          <Card.Body>
            <Card.Title>Register</Card.Title>
            <Form noValidate validated={validated}>
              {/* email */}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                  isInvalid={validated && !email}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>

              {/* username */}
              <Form.Group controlId="formBasicUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  isInvalid={validated && !username}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a username.
                </Form.Control.Feedback>
              </Form.Group>

              {/* password */}
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  isInvalid={validated && !password}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a password.
                </Form.Control.Feedback>
              </Form.Group>

              {/* submit button */}
              <Button
                className="mt-2"
                variant="danger"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                {isLoading ? 
              <Spinner animation="border" role="status">
              
            </Spinner>
            :
              <span className="text-white">Register</span>
              
              }
              </Button>
            </Form>
           
          <p className="text-success mt-1">Register your account and wait for approval</p>
          </Card.Body>
        </Card>
      </div>

    </>
  );
}