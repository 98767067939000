import React, { useState, useEffect } from 'react';
import { useStateContext } from '../hooks/StateContextProvider';

function SearchComponent() {
  const { setSearchTerm, searchTerm } = useStateContext();

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm, setSearchTerm]);

  return (
    <input
      className="py-2 mt-2 col-xs-12 col-md-6 block outline outline-1 rounded "
      type="text"
      placeholder="Search for available shows or movies"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      autoComplete="off"
    />
  );
}

export default SearchComponent;
