import React from "react";

function LoadingPage() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <h1 className="text-white">Loading</h1>
      <div
        className="spinner-grow text-success ms-1"
        style={{ width: "0.5rem", height: "0.5rem" }}
        role="status"
      ></div>
      <div
        className="spinner-grow text-success ms-1"
        style={{ width: "0.7rem", height: "0.7rem" }}
        role="status"
      ></div>
      <div
        className="spinner-grow text-success ms-1"
        style={{ width: "0.9rem", height: "0.9rem" }}
        role="status"
      ></div>
    </div>
  );
}

export default LoadingPage;
