import {Routes, Route } from "react-router-dom";
import Requests from "../Pages/Requests";
import Shows from "../Pages/AllShows";
import AdminPage from "../Pages/AdminPage";
import AllMovies from "../Pages/AllMovies";
import DatabasePage from "../Pages/DatabasePage";
import TorInterract from "../Pages/TorInterract";
import HlsPlayer from "../Pages/MainPlayer";


export default function  ProtectedRoute () {

  
  return (
    <Routes>
        <Route exact path='requests' element={<Requests/>}/>
        <Route exact path='allmovies' element={<AllMovies/>}/>
        <Route exact path='shows' element={<Shows/>}/>
        <Route exact path='adminPage' element={<AdminPage/>}/>
        <Route exact path='database' element={<DatabasePage/>}/>
        <Route exact path="searchTor" element={<TorInterract/>} />
        <Route exact path="player" element={<HlsPlayer/>} />
    </Routes>
  );

}
