import React, {useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { handleItemDelete } from '../utils/updateMongoDbData';

function DeleteConfirmationModal({ show, onHide, user, path, isLoading, setIsLoading, item, setDeleteItems,setShowModal, showModal, handleUpdateDatabase  }) {
  const [deleteItem, setDeleteItem] = useState(path ? path : "");

  const onConfirm = async () => {
    await handleItemDelete(user, item._id, deleteItem, setIsLoading);
    setDeleteItems({ id: "", path: "" });
    await handleUpdateDatabase();
    setShowModal({
      delete: !showModal.delete,
    });
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      {item.type === "Series"
       &&
        <Form.Group controlId="formCategory">
                        <Form.Label>Current Item to Delete: {deleteItem}</Form.Label>
                        <Form.Control
                            as="select"
                            value={deleteItem}
                            onChange={(e) => setDeleteItem(e.target.value)}
                        >
                            <option value="">Select Item</option>
                            {item.path.map((item, index) => 
                              <option key={index} value={item}>{item.split("/")[2].split(".")[0]}</option>
                              
                            )}
                        </Form.Control>
                    </Form.Group>
    }
      <Modal.Body>
        Are you sure you want to delete {path} this item from your AWS bucket? This action cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        {!isLoading
        ?
          <Button variant="danger" onClick={onConfirm}>
          Delete
        </Button>
      :
      <Button variant="danger" onClick={onConfirm}>
      <div class="spinner-border" role="status">
      </div>
    </Button>
      }
        
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
