import React, {useState, createContext} from "react";
export const StateContext = createContext();



export const StateContextProvider = ({children}) => {
    const  [isLoading, setIsLoading]=useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isSetup, setIsSetup] = useState(true);
    const [movieDatabase, setMovieDatabase] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [otpVisible, setOtpVisible] = useState(false);

    return(
        <StateContext.Provider value={{isLoading, setIsLoading, otpVisible, setOtpVisible, searchResults, setSearchResults, searchTerm, setSearchTerm, isMobile, setIsMobile, isSetup, setIsSetup, movieDatabase, setMovieDatabase}}>
            {children}
        </StateContext.Provider>
    )
}