import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

export default function TorInterract() {
  const [sort_by, set_sort_by] = useState();
  const [genre, setGenre] = useState();
  const [order_by, set_order_by] = useState();
  const [filterYear, setFilterYear] = useState();
  const [movieData, setMovieData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const genres = [
    "Action",
    "Comedy",
    "Drama",
    "Fantasy",
    "Music",
    "Musical",
    "Romance",
    "Sci-Fi",
  ];
  const order = ["asc", "desc"];
  const sort = [
    "title",
    "year",
    "rating",
    "peers",
    "seeds",
    "download_count",
    "like_count",
    "date_added",
  ];

  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear-1, currentYear-2, currentYear-3, currentYear-4];

  const fetchDataFromAPI = async () => {
    try {
      const series = await axios.get(`https://yts.mx/api/v2/list_movies.json`, {
        params: {
          order_by: order_by && order_by,
          sort_by: sort_by && sort_by,
          genre: genre && genre,
          limit: 50,
          page: pageNumber,
        },
      });
      // const showLinks = await showURL(series);
      if(filterYear){
        setMovieData(series.data.data.movies.filter(item => item.year === filterYear));
      }else{
        setMovieData(series.data.data.movies);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleChangeOrder = (event) => {
    set_order_by(event.target.value);
  };

  const handleChangeGenres = (event) => {
    console.log(event.target.value);
    setGenre(event.target.value);
  };

  const handleChangeSort = (event) => {
    console.log(event.target.value);
    set_sort_by(event.target.value);
  };

  const handleChangePage = (pageNumber, operator) => {
    operator ? setPageNumber(pageNumber + 1) : setPageNumber(pageNumber - 1);
    return fetchDataFromAPI();
  };

  const handleChangeYear = (event) => {
    setFilterYear (+event.target.value);
  };

  return (
    <div id="page">
      <div
        className="text-white"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="torPage">
          <label htmlFor="genres">Choose a genre:</label>
          <select
            name="genres"
            id="genres"
            onChange={(e) => handleChangeGenres(e)}
          >
            {genres &&
              genres.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
          </select>

          <label htmlFor="sort">Choose Sort Method:</label>
          <select name="sort" id="sort" onChange={(e) => handleChangeSort(e)}>
            {sort &&
              sort.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
          </select>

          <label htmlFor="order">Choose Sort Order:</label>
          <select
            name="order"
            id="order"
            onChange={(e) => handleChangeOrder(e)}
          >
            {order &&
              order.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
          </select>

          <label htmlFor="order">Choose Sort Order:</label>
          <select
            name="order"
            id="order"
            onChange={(e) => handleChangeYear(e)}
          >
            {years &&
              years.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
          </select>

          <button
            onClick={(e) => fetchDataFromAPI()}
            className="btn btn-primary"
          >
            Get Data
          </button>
        </div>

        <div
          className="card-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {movieData ? (
            movieData.filter(item => item.language === "en").map(
              (item, index) =>
                item.language === "en" && (
                  <div
                    key={index}
                    className="card"
                    style={{ width: "10rem", margin: "1rem" }}
                  >
                    <img
                      className="card-img-top"
                      alt="No_Image"
                      src={item.medium_cover_image}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.title_english}</h5>
                      <p className="card-text">{item.year}</p>
                      <a href={item.torrents[0]} className="card-link">
                        720p
                      </a>
                      <a href={item.torrents[1]} className="card-link">
                        1080p
                      </a>
                      {item.yt_trailer_code && (
                        <a
                          href={`https://www.youtube.com/watch?v=${item.yt_trailer_code}`}
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Watch Trailer
                        </a>
                      )}
                    </div>
                  </div>
                )
            )
          ) : (
            <div>Click on the Get Data </div>
          )}
        </div>

        {movieData && (
          <div style={{display:"flex", justifyContent:"center", margin:"1rem"}}>
            <button
              onClick={() => handleChangePage(pageNumber, false)}
              className="btn btn-primary"
            >
              Prev Page
            </button>
            <h4>Current Page: {pageNumber}</h4>
            <button
              onClick={() => handleChangePage(pageNumber, true)}
              className="btn btn-primary"
            >
              Next Page
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
