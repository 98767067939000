import React, { useState, useEffect, useRef } from "react";
import Hls from "hls.js";
import { useLocation  } from "react-router-dom"; //Add useNavigate for add automatic navigation
import { Link } from "react-router-dom";
import { Col , Image} from "react-bootstrap";
import {
  deleteWatchHistoryItem,
  updateHistoryInLocalStorage,
} from "../utils/getAllHistoryItems";
import { useAuthContext } from "../hooks/useAuthContext";
import { getRandomMovieSuggestions } from "../utils/getRandomMovieSuggestions";

const HlsPlayer = () => {
  const videoRef = useRef(null);
  const [suggestions, setSuggestions] = useState(false); // State to control the display of the movie title
  // const navigate = useNavigate();

  const {
    url,
    title,
    episode,
    path,
    overview,
    genre,
    rating,
    year,
    genres,
    summary,
    Items
  } = useLocation().state;
  const { user } = useAuthContext();

  const genreID = {
    28: "Action",
    12: "Adventure",
    16: "Animation",
    35: "Comedy",
    80: "Crime",
    99: "Documentary",
    18: "Drama",
    10751: "Family",
    14: "Fantasy",
    36: "History",
    27: "Horror",
    10402: "Music",
    9648: "Mystery",
    10749: "Romance",
    878: "Science Fiction",
    10770: "TV Movie",
    53: "Thriller",
    10752: "War",
    37: "Western",
  };

  const filterGenres = (genre) => {
    let data = "";
    for (const key in genre) {
      const id = genre[key];
      if (genreID.hasOwnProperty(id)) {
        data += " *";
        data += genreID[id];
      }
    }
    return data;
  };

  useEffect(() => {
    const summaryElement = document.getElementById("summary-text");
    if (summaryElement) {
      summaryElement.innerHTML = summary;
    }
  }, [summary]);

  useEffect(() => {
    const video = videoRef.current;
    const hls = new Hls();

    // Get the saved playback data for the user from localStorage
    const savedData = JSON.parse(localStorage.getItem(user.id));

    if (savedData) {
      // Find the specific movie data using its title
      const updatedTitle = episode ? title + " " + episode : title;

      const movieData = savedData.find((movie) => movie.title === updatedTitle);

      if (movieData && movieData.currentTime) {
        // Set the video current time to the saved playback time for that movie
        video.currentTime = parseFloat(movieData.currentTime);
      }
    }

    if (Hls.isSupported()) {
      hls.attachMedia(video);
      hls.loadSource(`https://s5o2.c20.e2-7.dev/hlsbucket/${url}`);

      // Listen for manifest parsed event
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        console.log("Manifest parsed, ready to play.");
        video.play();
        // Optionally, you could play here if it's appropriate for your use case
      });


      // Assign hls instance to video object
      video.hls = hls;
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = `https://s5o2.c20.e2-7.dev/hlsbucket/${url}`;
    }

    // Save the current playback time periodically
    const savePlaybackPosition = () => {
      // localStorage.setItem(user.id, JSON.stringify([{"title":title, "playback": video.currentTime, "currentTime":new Date() }]));
      updateHistoryInLocalStorage(user.id, title, video.currentTime, episode);
      // localStorage.setItem(`playback_${title}`, video.currentTime, new Date());
      // const playbackItems = {key:`playback_${title}`, playbackTime:video.currentTime}
      // updateHistoryItemsInDatabase(user.accessToken, playbackItems);
    };

    // Function to check if there are 10 minutes or less remaining
    const checkRemainingTime = () => {
      const remainingTime = video.duration - video.currentTime;
      if (remainingTime <= 240 && episode) {
        // 240 seconds = 4 minutes
        setSuggestions(true);
      } else {
        setSuggestions(false);
      }
      if (remainingTime <= 600) {
        // 600 seconds = 10 minutes
        setSuggestions(true);
      } else {
        setSuggestions(false);
      }
    };

    video.addEventListener("timeupdate", savePlaybackPosition);
    video.addEventListener("timeupdate", checkRemainingTime); // Add event listener for remaining time check

    return () => {
      video.removeEventListener("timeupdate", savePlaybackPosition);
      // Optionally, you can remove the playback position when
      video.addEventListener("ended", () => {
        deleteWatchHistoryItem(user, title);
        // playNextVideo();
      });
    };

  }, [title, url, user, episode]);

  const getEpisode = (item) => {
    let parts = item.split("/");
    let lastPart = parts[parts.length - 2]; // Access the last element of the array using parts.length - 1
    let episodeInfoWithExtension =
      lastPart.split(" ")[lastPart.split(" ").length - 1]; // Access the last element after splitting by ' '
    // let episodeInfoWithoutExtension = episodeInfoWithExtension.split(".")[0]; // Remove the file extension
    return episodeInfoWithExtension;
  };

  // function playNextVideo() {
  //   const currentIndex = path.indexOf(url);
  //   const totalEpisodes = path.length;
  //   const renderNextEpisode = currentIndex < totalEpisodes;
  //   console.log(renderNextEpisode)
  //   console.log(episode);

  //   if (!episode) {
  //     navigate("/user/player",
  //       {
  //         state: {
  //           url: randomMovie.path[0],
  //           title: randomMovie.title,
  //           overview: randomMovie.overview,
  //           genre: randomMovie.genreID,
  //           rating: randomMovie.rating,
  //           randomMovie: randomMovie,
  //         },
  //       });
  //   }else if(renderNextEpisode){
  //     console.log("rendering next episode");
  //     navigate("/user/player", {

  //         state:{
  //           url: path[currentIndex + 1],
  //           title: title,
  //           path: path,
  //           episode: getEpisode(path[currentIndex + 1]),
  //           rating: rating,
  //           genres: genres,
  //           year: year,
  //           summary: overview,
  //         }
  //     })
  //   }
  // }

  function NextEpisodeLink() {
    const currentIndex = path.indexOf(url);
    const totalEpisodes = path.length;
    const renderNextEpisode = currentIndex + 1 < totalEpisodes;

    return (
      renderNextEpisode && (
        <Link
          to={"/user/player"}
          style={{ textDecoration: "none" }}
          state={{
            url: path[currentIndex + 1],
            title: title,
            path: path,
            episode: getEpisode(path[currentIndex + 1]),
            rating: rating,
            genres: genres,
            year: year,
            summary: overview,
          }}
        >
          <h4 id="simple-list-item-1">
            <div className="card text-wrap" style={{ width: "15rem" }}>
              <div className="card-body">
                Watch {getEpisode(path[currentIndex + 1])}
              </div>
            </div>
          </h4>
        </Link>
      )
    );
  }

  function MovieSuggestion() {
    const randomMovie = getRandomMovieSuggestions(Items);
    return (
      // <Link
      //   to={"/user/player"}
      //   state={{
      //     url: randomMovie.path[0],
      //     title: randomMovie.title,
      //     overview: randomMovie.overview,
      //     genre: randomMovie.genreID,
      //     rating: randomMovie.rating,
      //     Items:Items,
      //   }}
      //   style={{
      //     position: "absolute",
      //     top: "50%",
      //     left: "50%",
      //     transform: "translate(-50%, -50%)",
      //     opacity: "1",
      //     width:"200px",
      //     display:"flex",
      //     justifyContent:"center",
      //     textDecoration:"none",
      //     padding:"5px",
      //     borderColor:"1px solid white",
      //     borderRadius:"5px" 
      //   }}
      // >
      //   <p>Play Next {randomMovie.title}</p>
      //   <svg
      //     xmlns="http://www.w3.org/2000/svg"
      //     width="50"
      //     height="50"
      //     fill="currentColor"
      //     className="bi bi-play-circle-fill"
      //     viewBox="0 0 16 16"
      //   >
      //     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
      //   </svg>
      // </Link>



<div
            style={{
              height: "200px",
              position: "relative",
              display: "inline-block",
              marginInline: "3px",
            }}
          >


            
            <p
              style={{
                position: "absolute",
                bottom: "-15px",
                fontWeight: "900",
                color: "red",
                backgroundColor: "black",
                paddingInline: "3px",
                borderRadius: "10px",
                marginInline: "50%",
              }}
            >
              {randomMovie.title}
            </p>

            <Image
              style={{
                height: "100%",
                width: "auto",
              }}
              variant="top"
              src={randomMovie.img}
            />
            <Link
              to={"/user/player"}
              state={{
                url: randomMovie.path[0],
                title: randomMovie.title,
                overview: randomMovie.overview,
                genre: randomMovie.genreID,
                rating: randomMovie.rating,
                Items:Items,
              }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: "0.8",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="bi bi-play-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
              </svg>
            </Link>
      
          </div>
    );
  }

  return (
    <div style={{ justifyContent: "center", marginBottom: "400px" }}>
      <h1
        variant="h4"
        style={{ margin: "15px", justifyContent: "center" }}
        className="text-white"
      >
        {title} {episode ? episode : null}
      </h1>
      <div
        className="player-container col-md-8 col-sm-12"
        style={{ marginLeft: "10px", position: "relative" }}
      >
        <video ref={videoRef} controls width="100%" />

        {suggestions && ( // Display movie title when there are 10 minutes left
          <div
            style={{
              position: "absolute",
              bottom: "100px",
              right: "250px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            {episode ? <NextEpisodeLink /> : <MovieSuggestion />}
          </div>
        )}
      </div>
      <p
        variant="h5"
        style={{ margin: "15px", justifyContent: "center" }}
        className="text-white"
      >
        {overview}
      </p>
      <div>
        <h6
          variant="h4"
          style={{ margin: "15px", justifyContent: "center" }}
          className="text-white"
        >
          {filterGenres(genre)}
        </h6>
        <h6
          variant="h4"
          style={{ margin: "15px", justifyContent: "center" }}
          className="text-white"
        >
          Movie Rating: {rating ? parseFloat(rating.toFixed(1)) : 0}
        </h6>
      </div>
      {/* Scroll Bar */}
      {path ? (
        <div className="row-2 ms-1 d-flex col-md-8 col-sm-12">
          <div className="scrollmenu ">
            {path.map((item, index) => (
              <Link
                to={"/user/player"}
                key={index}
                style={{ textDecoration: "none" }}
                state={{
                  url: item,
                  title: title,
                  path: path,
                  episode: getEpisode(item),
                  rating: rating,
                  genres: genres,
                  year: year,
                  summary: overview,
                }}
              >
                <h4 id="simple-list-item-1">
                  <div className="card text-wrap" style={{ width: "15rem" }}>
                    <div className="card-body">Watch {getEpisode(item)}</div>
                  </div>
                </h4>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
      {summary ? (
        <Col lg={6} xs={12}>
          <p id="summary-text" className="text-white ms-3 mt-1"></p>
        </Col>
      ) : null}
    </div>
  );
};

export default HlsPlayer;
