import { StateContext } from "../Context/StateContext";
import { useContext } from "react";

export const useStateContext = () => {
    const context = useContext(StateContext);

    if(!context){
        throw Error(
        'useAuthContext must be used inside an StateContextProvider');
    }
    return context;
}