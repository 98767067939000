import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  fetchAllUsers,
  changeUserStatus,
  otpRequirement,
} from "../hooks/useFetchUsers";
import {toast} from 'react-toastify';
import { Link } from "react-router-dom";

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [userLoading, setUserLoading] = useState({});
  const [userOTPLoading, setUserOTPLoading] = useState({});
  const { user } = useAuthContext();

 const fetchUserData = useCallback (async () => {
    if (user.accessToken) {
      const userData = await fetchAllUsers(user.accessToken);
      setUsers(userData);
    }
  },[user.accessToken]);


  // Approve User Status
  const handleStatus = async (email) => {
    try {
      setUserLoading((prevUserLoading) => ({
        ...prevUserLoading,
        [email]: true,
      }));
   
      const response = await changeUserStatus(user.accessToken, email);
      if (response.data.success) {
        toast.success(response.data.message);

        setTimeout(() => {
          setUserLoading((prevUserLoading) => ({
            ...prevUserLoading,
            [email]: false,
          }));
          fetchAllUsers(user.accessToken);
        }, 1000);


      } else {
        toast.success(response.data.message);
        setUserLoading((prevUserLoading) => ({
          ...prevUserLoading,
          [email]: false,
        }));
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setUserLoading((prevUserLoading) => ({
        ...prevUserLoading,
        [email]: false,
      }));
    }
  };

  const handleOTP = async (email) => {
    try {
      setUserOTPLoading((prevUserLoading) => ({
        ...prevUserLoading,
        [email]: true,
      }));
   
      const response = await otpRequirement(user.accessToken, email);

      if (response.data.success) {
        toast.success(response.data.message);

        setTimeout(() => {
          setUserOTPLoading((prevUserLoading) => ({
            ...prevUserLoading,
            [email]: false,
          }));
          fetchAllUsers(user.accessToken);
        }, 4000);
      } else {
        toast.success(response.data.message);
        setUserOTPLoading((prevUserLoading) => ({
          ...prevUserLoading,
          [email]: false,
        }));
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setUserOTPLoading((prevUserLoading) => ({
        ...prevUserLoading,
        [email]: false,
      }));
    }
  };

  
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);



  return (
    <div style={{ marginBottom: "700px" }}>
      {/* List of requested movies */}
      <Link to="/user/database"><button type="button" className="btn btn-dark text-white">Database</button></Link>
      <div className="d-flex justify-content-center mt-1 mb-1">
        <h1 className="text-white">Users</h1>
      </div>
      <div className="d-flex justify-content-center overflow-scroll">
        <div>
          {users && users.length > 0 ? (
            <table className="table table-dark table-stripped table-responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Email</th>
                  <th scope="col">OTP</th>
                  <th scope="col">Status</th>
                  <th scope="col">Member</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td className="text-wrap">{user.email}</td>
                    <td>
                    {userOTPLoading[user.email] ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <Button
                          size="sm"
                          className="text-white"
                          variant={
                            user.otp ? "success" : "outline-warning"
                          }
                          onClick={(e) => handleOTP(user.email)}
                        >
                          {user.otp ? "Active" : "Inactive"}
                        </Button>
                      )}
                    </td>
                    <td>
                      {new Date(user.activity).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </td>
                    <td>
                      {userLoading[user.email] ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <Button
                          size="sm"
                          className="text-white"
                          variant={
                            user.approved ? "success" : "outline-warning"
                          }
                          onClick={(e) => handleStatus(user.email)}
                        >
                          {user.approved ? "Active" : "Inactive"}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <li className="d-flex justify-content-center text-white">
              Require Administrator Permission
            </li>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
