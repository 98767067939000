import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import "../App.css";
import { Link } from "react-router-dom";
import Timer from "../Component/Timer.js";
import { useStateContext } from "../hooks/StateContextProvider.js";
import { useAuthContext } from "../hooks/useAuthContext.js";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const { setIsLoading, setOtpVisible, otpVisible, isLoading } =
    useStateContext();
  const { dispatch } = useAuthContext();

  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_BACKEND}/free`,
    );
  },[])

  // Handle Password Submit
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/auth/signin`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (result.data.otpExpiry) {
        setOtpVisible(true);
        setIsLoading(false);
        toast.info(result.data.message);
      } else {
        localStorage.setItem("user", JSON.stringify(result.data));
        localStorage.setItem(
          "tokenExpiration",
          result.data.tokenExpiry.toString()
        );
        dispatch({ type: "LOGIN", payload: result.data });
        toast.success(`Login successful! Welcome ${result.data.username}`);
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/user/aws";
        }, 2000);
      }
    } catch (error) {
      // Handle errors more informatively
      console.log(error);
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  // Handle after OTP submit
  const handleOTPSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/auth/verifyOTP`,
        {
          email,
          otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("user", JSON.stringify(result.data));
      localStorage.setItem(
        "tokenExpiration",
        result.data.tokenExpiry.toString()
      );
      dispatch({ type: "LOGIN", payload: result.data });
      toast.success(`Login successful! Welcome ${result.data.username}`);

      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/user/aws";
      }, 2000);
      // Consider adding a success message or popup here
    } catch (error) {
      // Handle errors more informatively
      // setToastVariant("danger");
      setIsLoading(false);
      console.log(error.response.data.message);
    }
  };

  // user Google Login

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsLoading(true);
        // console.log("Google login successful", tokenResponse);
        // Use the tokenResponse to authenticate the user in your app
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/auth/google`,
          tokenResponse,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // Login successful, redirect or store token

        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("tokenExpiration", response.data.tokenExpiry.toString());
        dispatch({ type: "LOGIN", payload: response.data });
        toast.success(`Login successful! Welcome ${response.data.username}`);

        setIsLoading(false);
        // return setTimeout(() => {
        //   redirect("/secret");
        // }, 3000);
      } catch (error) {
        toast.error("Authentication failed");
        console.log(error);
        // Handle errors here, e.g., show a message to the user
      }
    },
    onError: () => {
      toast.error("Google login failed");
      // Handle login errors here
    },
    flow: "auth-code", // Use 'auth-code' for the authorization code flow
  });

  return (
    <>
      <div className="container">
        <Card className="card" style={{ width: "20rem" }}>
          <Card.Body>
            <Card.Title>Login</Card.Title>
            <Form>
              {/* email */}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </Form.Group>

              {/* password */}
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Form.Group>

              {otpVisible ? (
                <>
                  {/* email */}
                  <Form.Group controlId="formBasicOtp" className="mt-2">
                    <Form.Label className="text-success">
                      <strong>MS-Media_OTP Code</strong>
                    </Form.Label>
                    <Form.Control
                      required
                      type="otp"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP Code"
                    />
                  </Form.Group>

                  {/* submit button */}
                  <Button
                    className="mt-2"
                    variant="success"
                    type="submit"
                    onClick={(e) => handleOTPSubmit(e)}
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <span className="">Submit</span>
                    )}
                  </Button>
                  {<Timer timersecs={120} />}
                </>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="mt-2"
                    variant="success"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <span className="">
                        Login
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-in-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                          />
                        </svg>
                      </span>
                    )}
                  </Button>
                  <Link to="/forgotpassword">Forgot Password</Link>
                </div>
              )}

              <br />
            </Form>
            <Button onClick={() => googleLogin()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-google me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
          </svg>
          Sign In With Google
        </Button>
          </Card.Body>
        </Card>

      </div>
    </>
  );
}
