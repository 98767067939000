import axios from "axios";
import {toast} from 'react-toastify';

export const fetchAllUsers = async (token) => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND + "/api/users/fetch",
      {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });
      return response.data.simplifiedUsers;
  
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  export const otpRequirement = async (token, email) => {
    try {
        const response = await axios.post(process.env.REACT_APP_BACKEND + "/api/users/otpRequirement",
        {email},
        {
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        });
        return response;
    
      } catch (error) {
        toast(error.response.data.message);
      }
  }

  export const changeUserStatus = async (token, email) => {
    try {
        const response = await axios.post(process.env.REACT_APP_BACKEND + "/api/users/userApproval",
        {email},
        {
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        });
        return response;
    
      } catch (error) {
        toast(error.response.data.message);
      }
  }