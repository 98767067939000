import axios from "axios";
import { toast } from "react-toastify";

export const updateMongoDbData = async (token, setIsLoading, fetchData) => {
  setIsLoading(true);
  try {

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/mongo/update`,
          {}, // Empty object as data
          {
            headers: {
              "x-access-token": token,
              "Content-Type": "application/json",
            },
          }
        );
        setIsLoading(false);
        fetchData();
        return toast.success(response.data.message); // Return the response data if needed

      } catch (error) {
      setIsLoading(false);
        return toast.error(error.response.data.message);
      }
};

export const handleItemDelete = async (user, id, path, setIsLoading) => {
  setIsLoading(true);
  if (user) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/mongo/deleteObject`,
        {
          id:id,
          key: path,
        },
        {
          headers: {
            "x-access-token": user.accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      toast.success(response.data.message);
    } catch (error) {
      setIsLoading(false);
      toast.error("Error deleting item.");
    }
  }
};

export const renameAWSData = async (token, setIsLoading, fetchData, oldKey, newKey, _id) => {
  setIsLoading(true);
  try {

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/mongo/updateObject`,
          {
            oldKey:oldKey,
            newKey:newKey,
            _id:_id
          }, // Empty object as data
          {
            headers: {
              "x-access-token": token,
              "Content-Type": "application/json",
            },
          }
        );
        setIsLoading(false);
        fetchData()
        return toast.success(response.data.message); // Return the response data if needed

      } catch (error) {
      setIsLoading(false);

        return toast.error(error.response.data.message);
      }
};