import React, { useState } from 'react'
import axios from "axios";
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Timer from "../Component/Timer.js";
import { config } from "../App.js"
import "../App.css";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);


  //Handle Password Submit
  const handleSubmit = async (e) => {

    setIsLoading(true);
    e.preventDefault();

    try {
      const result = await axios({
        method: "post",
      url: `${config.endpoint}/api/auth/forgotpassword`,
      data: {
        email,
      }
      });
      
      setOtpVisible(true);
      toast.info(result.data.message);
      setIsLoading(false);
    } catch (error) {
      // Handle errors more informatively
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };



  //Handle after OTP submit
  const handleOTPSubmit = async (e) => {
    if(email === ""){
      setIsLoading(false);
      return toast.error("Invalid Email adres");
    }

    if(password === ""){
      setIsLoading(false);
      return toast.error("Invalid Password");
    }

    setIsLoading(true);
    e.preventDefault();

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/auth/reset`,
        {
          email,
          password,
          otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      setIsLoading(false);
      toast.success(result.data.message);
      

      setTimeout(() => {
        setIsLoading(false);
        window.location.href = '/login';
      }, 4000);


    } catch (error) {
      // Handle errors more informatively
      setIsLoading(false);
      return toast.error(error.response.data.message);
    }
  };



    return (
    <>
    <div className="container">
      <Card className="card" style={{ width: '20rem' }}>
        <Card.Body>
          <Card.Title>Forgot Password</Card.Title>
          <Form>
            {/* email */}
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </Form.Group>

            {
              otpVisible ?
              <>

            {/* password */}
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Enter New Password</Form.Label>
              <Form.Control
                required
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </Form.Group>

            {/* OTP Input */}
            <Form.Group controlId="formBasicOtp" className='mt-2'>
              <Form.Label className='text-success'><strong>MS-Media_OTP Code</strong></Form.Label>
              <Form.Control
                required
                type="otp"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP Code"
              />
            </Form.Group>

           
            {/* submit button */}
            <Button
              className="mt-2"
              variant="success"
              type="submit"
              onClick={(e) => handleOTPSubmit(e)}
            >
              {isLoading ?
              <Spinner animation="border" role="status"></Spinner>
              :
                <span className="">Submit</span>
              }
            </Button>
            {
              <Timer timersecs={120} />
            }
      </>
            :
            (
            <Button
            className="mt-2"
            variant="success"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {isLoading ?
              <Spinner animation="border" role="status"></Spinner>
              :
              <span className="">Reset Password</span>
            }
            </Button>
            )
            }


            
            <br />


          </Form>

        </Card.Body>
      </Card>

      </div>
    </>


  )
}
