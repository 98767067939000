import React  from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
//This is used for search results on All Movies page

function MovieBlock({Items, Title}) {

  return (
        <>
              <div style={{ display: "flex", justifyContent: "center" }}>
              <h1 className="text-white mt-2">{Title}</h1>
            </div>
            <Row>
              {Items.map((item, index) => (
                <Col
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                  xxl={2}
                  className="mt-1 mb-3"
                  style={{
                    display: "flex",
                    objectFit: "cover",
                    justifyContent: "center",
                  }}
                  key={index}
                >
                  <Card
                    style={{ objectFit: "cover", backgroundColor: "#3a3a3a" }}
                  >
                    <Card.Img
                      className="d-flex"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                      variant="top"
                      src={item.img}
                    />
                    <Card.Body className="d-flex justify-content-center">
                      <Card.Title className="text-white">
                        <div className="text-wrap">{item.title}</div>
                      </Card.Title>
                    </Card.Body>
                    <Link
                      to={"/user/player"}
                      state={{ url: item.path, title: item.title, overview:item.overview, genre:item.genre, rating:item.rating }}
                    >
                      <Button style={{ width: "100%" }} variant="primary">
                        <strong>Watch Now</strong>
                      </Button>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
            
            
            </>
  );
}

export default MovieBlock;
