// src/NotFound.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/user/allmovies");
    }, 5000); // Redirect after 10 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-white">404</h1>
        <p className="text-2xl text-white mt-4">Page Not Found</p>
        <div className="flex justify-center items-center">
        <p className="text-lg text-white mt-2 flex pe-1 items-center">
          Redirecting to Home page in 5 seconds
        </p>
        <Spinner animation="grow" variant="light" />;
        </div>
      </div>
    </div>
  );
};

export default NotFound;