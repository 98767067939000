import axios from "axios";

export const updateHistoryItemsInDatabase = async (token, playbackItems) => {
  await axios.post(
    `${process.env.REACT_APP_BACKEND}/api/users/updateWatchHistory`,
    { playbackItems }, // Empty object as data
    {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    }
  );
};

export const clearAllWatchHistory = async (user) => {
  try {
    localStorage.removeItem(user.id);
    await axios.delete(
      `${process.env.REACT_APP_BACKEND}/api/users/clearWatchHistory`,
      {
        headers: {
          "x-access-token": user.accessToken,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Failed to clear watch history:", error);
  }
};

export const deleteWatchHistoryItem = async (user, title) => {
  try {

      // Get the existing data for the user from localStorage
  let playbackData = JSON.parse(localStorage.getItem(user.id)) || [];

  // Check if the movie title exists in the playbackData array
  const existingMovieIndex = playbackData.findIndex(
    (movie) => movie.title === title
  );

  if (existingMovieIndex > -1) {
    // Filter out the movie from the playbackData
    playbackData = playbackData.filter((item) => item.title !== title);
  } else {
    console.log(`${title} Not Found in Watch History`);
  }

  // Save the updated data back to localStorage
  localStorage.setItem(user.id, JSON.stringify(playbackData));

    await axios.delete(
      `${process.env.REACT_APP_BACKEND}/api/users/removeWatchHistoryItem`,
      {
        headers: {
          "x-access-token": user.accessToken,
          "Content-Type": "application/json",
        },
        data: { title },
      }
    );

  } catch (error) {
    console.error("Failed to delete watch history item", error);
  }
};

export const fetchWatchHistory = async (user) => {
  const response =  await axios.get(
    `${process.env.REACT_APP_BACKEND}/api/users/fetchWatchHistory`,
    {
      headers: {
        "x-access-token": user.accessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.watchHistory;
};

export const updateHistoryInLocalStorage = async (id, title, currentTime, episode) => {
  // Get the existing data for the user from localStorage
  let playbackData = JSON.parse(localStorage.getItem(id)) || [];
  const updatedTitle = episode ? title +" "+episode : title 


  // Check if the movie title already exists in the playbackData array
  const existingMovieIndex = playbackData.findIndex(
    (movie) => movie.title === updatedTitle
  );

  if (existingMovieIndex > -1) {
    // If the movie exists, update its playback time
    playbackData[existingMovieIndex].currentTime = currentTime;
    playbackData[existingMovieIndex].Timestamp = new Date();
  } else {
    // If the movie doesn't exist, add it to the array

    playbackData.push({
      title: updatedTitle,
      currentTime: currentTime,
      Timestamp: new Date(),
    });
  }

  // Save the updated data back to localStorage
  localStorage.setItem(id, JSON.stringify(playbackData));
};
