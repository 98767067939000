import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useStateContext } from "../hooks/StateContextProvider";
import MovieBlock from "../Component/MovieBlocks";
import ScrollData from "../Component/scrollData";
import LoadingPage from "../Component/LoadingComponent";
import { useAuthContext } from "../hooks/useAuthContext";
import { toast } from "react-toastify";
import { fetchMongoDbData } from "../utils/fetchMongoDbData";
import {
  updateHistoryItemsInDatabase,
  fetchWatchHistory,
  clearAllWatchHistory,
  deleteWatchHistoryItem
} from "../utils/getAllHistoryItems";

export default function AllMovies() {
  const [filteredData, setFilteredData] = useState([]);
  const [watchHistory, setWatchHistory] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [selectedYear, setSelectedYear] = useState("Select Year");
  const [selectedLanguage, setSelectedLanguage] = useState("Select Language");
  const { user } = useAuthContext();
  const { searchTerm, setMovieDatabase } = useStateContext();
  const movieTypes = [
    {
      ID: 28,
      Title: "Action Movies",
    },
    {
      ID: 35,
      Title: "Comedy Movies",
    },
    {
      ID: 10749,
      Title: "Romance Movies",
    },
    {
      ID: 10751,
      Title: "Family Movies",
    },
  ];

  const applyFilters = (year, language) => {
    let filteredItems = videoUrl;

    if (year !== "Select Year") {
      if (year === new Date().getFullYear() - 3) {
        filteredItems = filteredItems.filter((item) => {
          const itemYear = new Date(item.premiered).getFullYear();
          return itemYear < year;
        });
      } else {
        filteredItems = filteredItems.filter((item) => {
          const itemYear = new Date(item.premiered).getFullYear();
          return itemYear === year;
        });
      }
    }

    if (language !== "Select Language") {
      filteredItems = filteredItems.filter((item) => {
        const match = item.language.includes(language);
        return match;
      });
    }
    setFilteredData(filteredItems);
  };

  const handleFilterYear = (year) => {
    setSelectedYear(year);
    applyFilters(year, selectedLanguage);
  };

  const handleFilterLanguage = (language) => {
    setSelectedLanguage(language);
    applyFilters(selectedYear, language);
  };

  const watchHistoryMovies = useCallback(async (localStorageWatchHistory, movies) => {
    const cloudStorageWatchHistory = await fetchWatchHistory(user);
    
    if(!localStorageWatchHistory.length){
    const titles = cloudStorageWatchHistory.length ? cloudStorageWatchHistory.map((item) => item.title) : [] ;
    localStorage.setItem(user.id, JSON.stringify(cloudStorageWatchHistory))
    return movies && setWatchHistory(movies.filter((movie) =>
      titles.includes(movie.title)
    ));
    
  }

  cloudStorageWatchHistory.forEach((newItem) => {
      const existingIndex = localStorageWatchHistory.findIndex(
        (item) => item.title === newItem.title
      );

      if (existingIndex.length) {
        // Compare timestamps and update if the new timestamp is more recent
        const existingItem = localStorageWatchHistory[existingIndex];

      const existingTimestamp = new Date(existingItem.Timestamp);
        const newTimestamp = new Date(newItem.Timestamp);

        if (newTimestamp > existingTimestamp) {
          // Update the existing entry with the new item
          localStorageWatchHistory[existingIndex] = newItem;
        }
      } else {
        // Add the new item if it doesn't exist
        localStorageWatchHistory.push(newItem);
      }
    });


    // Extract titles from the localStorage keys
    const titles = localStorageWatchHistory.map((item) => item.title);

    // Filter the movies array to only include those that match the extracted titles
    return movies && setWatchHistory(movies.filter((movie) =>
      titles.includes(movie.title)
    ));

    // Log the filtered movies or do something else with them
  }, [user]);

  const removeWatchHistoryItem = (title) => {
        // Update local state after deletion
        deleteWatchHistoryItem(user, title);
        setWatchHistory((prevHistory) =>
          prevHistory.filter((item) => item.title !== title)
        );
  }

  const clearWatchHistory = () =>{
    setWatchHistory([]);
    clearAllWatchHistory(user)
  }

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const movies = await fetchMongoDbData("Movies", "", user.accessToken);
        if (movies) {
          setVideoUrl(movies);
          setFilteredData(movies);
          setMovieDatabase(movies);
          // const uniqueFilter = await generateFilters(movies);
          // setFilters(...uniqueFilter);
          // console.log([...uniqueFilter])
          // const playbackItems = await getAllPlaybackItems(user.accessToken);
          const localStorageWatchHistory = JSON.parse(localStorage.getItem(user.id)) || [];
          // setWatchHistory(playbackData);
          await watchHistoryMovies(localStorageWatchHistory, movies);
          localStorageWatchHistory.length && updateHistoryItemsInDatabase(user.accessToken, localStorageWatchHistory);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchDataFromAPI();
  }, [setMovieDatabase, user, watchHistoryMovies]);

  return (
    <>
      <div className="text-white">
        {videoUrl.length ? (
          searchTerm ? (
            <MovieBlock
              Items={videoUrl.filter((item) => {
                const searchTermLower = searchTerm.toLowerCase();

                for (let key in item) {
                  if (Object.prototype.hasOwnProperty.call(item, key)) {
                    if (
                      typeof item[key] === "string" &&
                      item[key].toLowerCase().includes(searchTermLower)
                    ) {
                      return true;
                    }
                  }
                }

                return false;
              })}
              Title={"Search Results"}
            />
          ) : (
            <Container fluid>
              <hr className="hr" style={{ color: "white" }} />

              <div
                className="btn-group"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedYear}
                  </button>

                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleFilterYear(new Date().getFullYear())
                        }
                      >
                        {new Date().getFullYear()}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleFilterYear(new Date().getFullYear() - 1)
                        }
                      >
                        {new Date().getFullYear() - 1}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleFilterYear(new Date().getFullYear() - 2)
                        }
                      >
                        {new Date().getFullYear() - 2}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleFilterYear(new Date().getFullYear() - 3)
                        }
                      >
                        Older than {new Date().getFullYear() - 3}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleFilterYear("Select Year")}
                      >
                        All
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedLanguage}
                  </button>

                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleFilterLanguage("Hindi")}
                      >
                        Hindi
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleFilterLanguage("English")}
                      >
                        English
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleFilterLanguage("Select Language")}
                      >
                        All
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <FilterDropDown /> */}

              {/* Watch History */}

              {watchHistory ? (
                <ScrollData
                  Items={watchHistory}
                  Title={"Watch History"}
                  clearAllWatchHistory = {() => clearWatchHistory()}
                  deleteItem={removeWatchHistoryItem}
                />
              ) : null}

              {/* Recently Added Movies */}
              <ScrollData
                Items={filteredData
                  .sort(
                    (a, b) =>
                      new Date(b.LastModified) - new Date(a.LastModified)
                  )
                  .slice(0, 10)}
                Title={"Recently Added"}
              />

              {/* Array of Different Genre rendering */}
              <>
                {videoUrl ? (
                  movieTypes.map((movie, index) => (
                    <ScrollData
                      key={index}
                      Items={filteredData.filter((item) =>
                        item.genreID.includes(movie.ID)
                      )}
                      Title={movie.Title}
                    />
                  ))
                ) : (
                  <p>Data Not available</p>
                )}
              </>

              {/* All Movies */}
              <ScrollData Items={filteredData} Title={`All Movies`} />
            </Container>
          )
        ) : (
          <LoadingPage />
        )}
      </div>
    </>
  );
}
