import React, { useState }  from "react";
import axios from "axios";
import { Row, Button} from "react-bootstrap";
import { Form, Toast } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { config } from "../App.js"

export default function Contact() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleSubmit = async (e) => {
    if(email === ""){
      setToastMessage("You need to enter the email");
    }

    if(message === ""){
      setToastMessage("You need to enter the Messge");
    }

    setIsLoading(true);
    e.preventDefault();

    try {
      const result = await axios({
        method: "post",
      url: `${config.endpoint}/about`,
      data: {
        email,
        message,
      }
      });
      
      setToastVariant('success');
      
      setIsLoading(false);
      setToastMessage(result.data.message);
      setMessage("");
      setEmail("");
      setShowToast(true);

    } catch (error) {
      // Handle errors more informatively
      setToastVariant('danger');
      setToastMessage(error.response.data.message);
      setIsLoading(false);
      return setShowToast(true);
    }
  };

  return (
    <div style={{display:"flex", justifyContent:"center", marginBottom:"600px"}}>
    <Row lg={6} style={{display:"flex", justifyContent:"center", width:"100%"}} >    
      
    <Form  style={{justifyContent:"center"}}>
          <h1 className="text-white" style={{textAlign:"center" }}>Contact</h1>
          {/* email */}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email address"
            />
          </Form.Group>

          {/* Message */}
          <Form.Group className="mb-3" style={{width:"100%"}} controlId="exampleForm.ControlTextarea1">
            <Form.Label>Your Message</Form.Label>
            <Form.Control
            required
             as="textarea" 
             rows={3} 
             value={message}
             onChange={(e) => setMessage(e.target.value)}
             placeholder="Your Message"
             />
          </Form.Group>

         
                <Button
                  variant="success"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  {
                  isLoading ?
                    <Spinner animation="border" role="status"></Spinner>
                    :
                    <span className="">Send</span>
                  }
                </Button>


        </Form>
          <br />
        
    </Row>
    <Toast
    show={showToast}
    onClose={() => setShowToast(false)}
    delay={5000}
    autohide
    bg={toastVariant}
    style={{
      width:'max-content',
      position: 'absolute',
      top: 80,
      left: 0,
    }}
  >
    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
  </Toast>
  </div>
  );
}