import React, {useEffect} from "react";

export default function FreeComponent() {

  useEffect (() => {
    setTimeout(() => {
      return window.location.href ='https://youtube.com' ;
    }, 2000);
  }, [])

  return (
    <div>
      <h1 className="text-center text-white">You can Watch youtube for free <br/> Bye!</h1>
    </div>
  );
}